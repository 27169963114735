import {useEffect, useRef} from "react";

/**
 * timeout hook
 * @param {function} callback   the callback function
 * @param {number} delay        interval delay in milliseconds
 * @return {Object} the timer ref
 */
function useTimer(callback, delay) {
    const timerRef = useRef();
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const handler = () => callbackRef.current();

        timerRef.current = setTimeout(handler, delay);

        return () => clearTimeout(timerRef.current);
    }, [delay]);

    return timerRef;
}

export default useTimer;
