import {setLocalStorageItem} from "../utils/local_storage";

const {useEffect} = require("react");

const useLocalStorageSetter = (page, key, value) => {
    useEffect(() => {
        setLocalStorageItem(page, key, value);
    }, [value]);
};

export default useLocalStorageSetter;
