import {useContext, useEffect, useState} from "react";

import {DateContext} from "../contexts/dates";

/**
 * round the given timestamp string to the given unit, i.e. "2022-05-02T12:56:58+02:00" with roundUnit "minute" -> "2022-05-02T12:57:00+02:00" in DateTime
 * @param {string} timestamp ISO string, i.e.
 * @param {"hour"|"minute"} roundUnit currently allowed only ""
 * @return {{roundedDT: DateTimeType}}
 */
const useRoundedDateTime = (timestamp, roundUnit = "minute") => {
    const {fromISO, getDT, setDT, plusDT} = useContext(DateContext);

    const [roundedDT, setRoundedDT] = useState();

    const lower_unit = {
        hour: {
            unit: "minute",
            divisor: 60
        },
        minute: {
            unit: "second",
            divisor: 60
        }
    };

    useEffect(() => {
        if (timestamp) {
            const originalDT = fromISO(timestamp);
            const roundFactor = Math.round(getDT(originalDT, lower_unit[roundUnit].unit) / lower_unit[roundUnit].divisor);
            setRoundedDT(setDT(plusDT(originalDT, roundUnit, roundFactor), {[lower_unit[roundUnit].unit]: 0}));
        }
    }, [timestamp, roundUnit]);

    return {roundedDT};
};

export default useRoundedDateTime;
