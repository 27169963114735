import {useEffect, useState} from "react";

/**
 * @typedef {Object} Dimensions
 * @property {number} width - The inner width of the screen
 * @property {number} height - The inner height of the screen
 */

/**
 * A getter to capture the window dimensions
 * @return {Dimensions} - The window Dimensions
 */
const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
};
/**
 * A custom hook to get the inner width and inner height of the window.
 * @return {Dimensions} - The window Dimensions
 */
export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};
