import "core-js/stable";
import "regenerator-runtime/runtime";

import React, {Suspense} from "react";
import {createRoot} from "react-dom/client";
import {I18nextProvider} from "react-i18next";
import {AuthProvider} from "react-oidc-context";
import {Provider} from "react-redux";

import i18n from "../config/i18n";
import App from "./components/app/app";
import Loading from "./components/shared/loading/loading";
import {injectStore} from "./middleware/axios.middelware";
import configureStore from "./redux/store/index";

window.onload = async function () {
    // passed globals from backend
    global.DEBUG = document.body.dataset.debug === "true";
    global.NODE_ENV = document.body.dataset.node_env;
    global.LOG_URL = document.body.dataset.log_url;
    global.LOG_NAME = document.body.dataset.log_name;
    global.SERVICE_VERSION = document.body.dataset.service_version;
    global.SERVICE_NAMESPACE = document.body.dataset.service_namespace;
    global.DISABLE_CRITICAL_DIFFERENCES = document.body.dataset.disable_critical_differences;
    global.ORCHESTRATOR_WS_URL_EXTERNAL = document.body.dataset.orchestrator_ws_url_external;
    global.ORCHESTRATOR_WS_PATH_EXTERNAL = document.body.dataset.orchestrator_ws_path_external;
    global.HELPDESK = document.body.dataset.helpdesk === "true";
    global.CLIENT_ID = document.body.dataset.client_id;

    // For the simulator
    global.ALLOWED_ADD_EMERGENCY = document.body.dataset.allowed_add_emergency;

    // For the print
    global.STIMULSOFT_LICENSE = document.body.dataset.stimulsoft_license;

    global.IDENTITY_SERVER_AUTHORITY = document.body.dataset.identity_server_authority;
    global.HTTPLOG_BASIC_AUTH_TOKEN = document.body.dataset.httplog_basic_auth_token;

    const handleSigninCallback = () => {
        // set url
        window.history.replaceState(null, "", window.location.origin + window.location.pathname);
    };
    // Config for oidc
    const oidcConfig = {
        authority: global.IDENTITY_SERVER_AUTHORITY,
        client_id: global.CLIENT_ID,
        redirect_uri: window.location.origin + window.location.pathname,
        onSigninCallback: handleSigninCallback,
        accessTokenExpiringNotificationTimeInSeconds: 60 * 5 // access token will be renewed 5 min prior to the expiration time
    };

    const store = configureStore();
    injectStore(store);
    const container = document.getElementById("app");
    const root = createRoot(container);
    root.render(
        <AuthProvider {...oidcConfig}>
            <Provider store={store}>
                <Suspense fallback={<Loading fullscreen />}>
                    <I18nextProvider i18n={i18n}>
                        <App />
                    </I18nextProvider>
                </Suspense>
            </Provider>
        </AuthProvider>
    );
};
