/**
 * @fileoverview previous value hook
 *
 * usage:
 * <code>
 *     const prevValue = usePrevious(value);
 * </code>
 */

import {useEffect, useRef} from "react";

/**
 * use previous hook
 * @param {*} value
 * @param {*} [initialValue]
 * @return {*}
 */
function usePrevious(value, initialValue) {
    const ref = useRef(initialValue);

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export default usePrevious;
