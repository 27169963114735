/**
 * @fileoverview toggle hook
 */

import {useState} from "react";
/**
 * Get number of the weekday, Monday is 1, Sunday is 7, if date is invalid, then returns -1
 * @callback ToggleStatusFunction
 * @return {Void}
 */

/**
 * toogle hook
 * @param {boolean} initialValue
 * @return {[Boolean, ToggleStatusFunction, ToggleStatusFunction, ToggleStatusFunction]}
 */
function useToggle(initialValue) {
    const [status, setStatus] = useState(Boolean(initialValue));

    const toggleStatus = () => setStatus(!status);

    const toggleOn = () => setStatus(true);

    const toggleOff = () => setStatus(false);

    return [status, toggleStatus, toggleOn, toggleOff];
}

export default useToggle;
