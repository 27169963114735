// @ts-check
"use-strict";

import {useEffect, useState} from "react";

import axios from "../middleware/axios.middelware";

const URL_PRIORITIES = "/orchestrator/organization/priorities";

/** @typedef {import("axios").AxiosResponse<{data: Array<string>, ok: boolean}>} ResponsePriorities */

/**
 * @param {String} organizationId
 * @return {Promise<ResponsePriorities>}
 */
const fetchPrioritiesApi = (organizationId) => axios.get(URL_PRIORITIES, {params: {organizationId}});

/**
 * A custom hook to fetch the priorities corresponding to each customer
 *
 * @param {String} organizationId
 * @return {{isLoading: Boolean, isError: Boolean, prioritiesList: Array<String>}}
 */
export const useFetchPriorities = (organizationId) => {
    const [prioritiesList, setPrioritiesList] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchPriorities = async () => {
            setIsError(false);
            setIsLoading(true);
            try {
                const {
                    data: {data}
                } = await fetchPrioritiesApi(organizationId);
                setPrioritiesList(data);
            } catch (error) {
                setIsError(true);
            }

            setIsLoading(false);
        };
        fetchPriorities();
    }, [organizationId]);

    return {isLoading, prioritiesList, isError};
};
