import {useEffect, useRef} from "react";

/**
 * interval hook
 * @param {function} callback                the callback function
 * @param {Object} options
 * @param {number|null} options.delay       interval delay in milliseconds
 * @param {boolean} options.immediately     execute callback immediately
 */
function useInterval(callback, {delay = 0, immediately = false} = {}) {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const handler = () => callbackRef.current();

        if (immediately) {
            handler();
        }

        if (delay !== null) {
            const timerId = setInterval(handler, delay);

            return () => clearInterval(timerId);
        }
        return null;
    }, [delay]);
}

export default useInterval;
